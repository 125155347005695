@import "../../../public/mixins/mixins.scss";

.impactWrapper {
  // background: var(--bg45, linear-gradient(180deg, #e3f8ff 0%, #fbfdfe 100%));
  // margin-top: 50px;
  .impactContainer {
    max-width: 1440px;
    margin: auto;
    padding: 60px;
    @include mobile() {
      padding: 20px;
    }
    @include flexContainer(column, center, center, 55px, 0);
    @include mobile() {
      row-gap: 20px;
    }
    .heading {
      @include font(32px, 300, #4a536a, 0px, 44px, normal);
      @include mobile() {
        font-size: 20px;
      }
      .highlightHeading {
        font-weight: 700;
        @include mobile() {
          font-size: 20px;
        }
      }
    }

    .infoWrapper {
      width: 100%;
      @include flexContainer(row, center, stretch, 10px, 32px, wrap);
      @include mobile() {
        column-gap: 10px;
      }
      .infoContainer {
        flex: 0 1 230px;
        @include mobile() {
          flex: 1 1 150px;
        }
        @include flexContainer(column, center, center);
        padding: 19px;
        border-radius: 20px;
        border: 1px solid #e4e8ff;
        background: #fff;

        .impactIconContainer {
          width: 90px;
          height: 90px;
          @include mobile() {
            width: 60px;
            height: 60px;
          }
        }
        .count {
          margin-top: 20px;
          @include font(26px, 700, #006daf, 0px, 22px, normal);
          @include mobile() {
            font-size: 20px;
          }
        }
        .name {
          margin-top: 8px;
          @include font(14px, 500, #006daf, 0px, 22px, normal);
          @include mobile() {
            font-size: 12px;
          }
        }
      }
    }
  }
}
