// Define breakpoints as variables
$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

@mixin flexContainer(
  $flexDirection: row,
  $justifyContent: unset,
  $alignItems: unset,
  $rowGap: 0,
  $colGap: 0,
  $flexWrap: nowrap
) {
  display: flex;
  flex-direction: $flexDirection;
  justify-content: $justifyContent;
  align-items: $alignItems;
  row-gap: $rowGap;
  column-gap: $colGap;
  flex-wrap: $flexWrap;
}

@mixin font(
  $fontSize,
  $fontWeight,
  $fontColor,
  $letterSpacing: auto,
  $lineHeight: normal,
  $fontStyle: normal
) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $fontColor;
  letter-spacing: $letterSpacing;
  line-height: $lineHeight;
  font-style: $fontStyle;
}

@mixin button($fontSize, $fontWeight, $padding) {
  @include font($fontSize, $fontWeight, #fff);
  padding: $padding;
  border-radius: 25px;
  background: #006daf;
  border: none;
  cursor: pointer;
}

@mixin buttonType1(
  $padding,
  $marginTop: 0px,
  $fontSize: 14px,
  $fontWeight: 600,
  $fontColor: #4a536a
) {
  @include font($fontSize, $fontWeight, $fontColor);
  padding: $padding;
  border-radius: 21px;
  background: #fff;
  border: 1px solid #cdcdcd;
  margin-top: $marginTop;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

@mixin underlineText($height, $bgColor, $bottomPos: -2px) {
  content: "";
  position: absolute;
  width: 100%;
  height: $height;
  background-color: $bgColor;
  bottom: $bottomPos;
  left: 0;
  border-radius: 2px;
}

// Mixin for mobile devices
@mixin mobile {
  @media only screen and (max-width: $breakpoint-mobile) {
    @content;
  }
}

// Mixin for tablets
@mixin tablet {
  @media only screen and (min-width: $breakpoint-tablet) {
    @content;
  }
}

// Mixin for desktops
@mixin desktop {
  @media only screen and (min-width: $breakpoint-desktop) {
    @content;
  }
}
